<template>
  <b-modal
    v-bind="$attrs"
    v-model="innerValue"
    modal-class="sfmodal"
    header-close-variant="black modal-header-button"
  >
    <template #modal-header>
      <slot name="modal-header"></slot>
    </template>

    <template #default>
      <div class="mb-4">
        <h3
          class="h5 mb-0"
          :class="titleClass"
        >
          {{ title }}
        </h3>
        <div
          v-if="subtitle"
          class="text-muted fs-1"
        >
          {{ subtitle }}
        </div>
      </div>
      <slot name="default"></slot>
    </template>
    <template #modal-title>
      <slot name="modal-title"></slot>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'SfModal',
  props: {
    titleClass: { type: String, required: false, default: null },
    title: { type: String, required: false, default: null },
    subtitle: { type: String, required: false, default: null },
    value: { type: Boolean, required: false, default: null },
  },
  emits: ['input'],
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>
